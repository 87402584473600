@import '../../styles/variables';
@import '../../styles/mixins';

.ctaWrap {
  background: $paywall-background;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.ctaContent {
  position: absolute;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  flex: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ctaTitle {
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  margin: 0 auto 20px;
  max-width: 400px;
  color: $product-secondary;
}

.ctaBody {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto 50px;
  max-width: 400px;
  color: $product-body;
}

.ctaButton {
  margin: 55px auto 0;
}
