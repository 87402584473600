@import '../../styles/variables';

@mixin ctaButton {
  left: 0;
  right: 0;
  max-width: 155px;
  position: absolute;
  transition: bottom 500ms;
}

@mixin ctaWrap {
  bottom: 0;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  transition: height 500ms;
  z-index: 10;
}

@mixin ctaContent {
  border-radius: 0;
  justify-content: flex-start;
  margin: 0 auto auto auto;
  max-width: 315px;
  min-width: 315px;
  position: relative;
  text-align: center;
  transition: height 500ms;

  @media (min-width: $x-large-mobile) {
    max-width: 448px;
  }

  @media (min-width: $tablet) {
    max-width: 544px;
  }

  @media (min-width: $desktopSm) {
    max-width: 640px;
  }
}

.ctaWrapShow {
  @include ctaWrap;
  height: 100%;
}

.ctaWrapHide {
  @include ctaWrap;
  height: 0;
}

.ctaContentShow {
  @include ctaContent;
  height: 100%;
}

.ctaContentHide {
  @include ctaContent;
  height: 0;
}

.ctaButtonShow {
  @include ctaButton;
  bottom: 30px;
}

.ctaButtonHide {
  @include ctaButton;
  bottom: -800px;
}

.ctaTitle {
  background: $paywall-background;
  margin: 0 auto;
  padding: 60px 0 10px 0;
  max-width: 100%;
  z-index: 3;

  @media (min-width: $tabletLg) {
    padding: 60px 0 10px 0;
  }
}

.ctaBody {
  background: $paywall-background;
  margin: 0 auto;
  padding: 0 0 18px 0;
  max-width: 100%;
  z-index: 2;

  @media (min-width: $tabletLg) {
    padding: 0 0 18px 0;
  }
}
