@import '../../styles/mixins';
@import '../../styles/variables';

/* Mobile and common styles by default */
.ctaSkeleton {
  @include aspect-ratio(16, 9);

  height: 100%;
}

.ctaContainer {
  background-color: $product-extra-light;
  height: 100%;
  position: relative;
}

.cta {
  align-items: center;
  backdrop-filter: blur(4px);
  display: flex;
  flex: 1;
  height: 100%;
}

.ctaBackgroundImage {
  @include aspect-ratio(16, 9);

  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.3;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.ctaImageWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.ctaImage {
  @include aspect-ratio(16, 9);

  border-radius: 8px;
  width: 90%;
}

.ctaHostAd {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 0;
  padding-left: 40px;
  padding-right: 10px;
  /* Note: this width matches .ctaImage above */
  width: 90%;

  a,
  span {
    font-family: $source-sans-pro;
    font-size: 13px;
    color: $white;
    letter-spacing: 0.2px;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 1px;
    display: inline-block;
    font-weight: 600;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }

    @media (min-width: $tablet) {
      right: 16px;
    }
  }

  span {
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
}

.ctaContentContainer {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
}

.ctaContent {
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-width: 300px;
}

.ctaContentStack {
  flex-direction: column;
}

.ctaHeading {
  color: $product-secondary;
  margin-bottom: 10px;
  text-align: center;
}

.ctaSubheading {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 20px;
  color: $product-secondary;
}

.ctaLinks {
  display: block;
  width: 100%;
}

.ctaWavetrakLink {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  button {
    &:hover {
      color: $product-extra-light;
    }
  }
}

.ctaUpgradeBtn {
  margin: 0 auto 10px;
}

.ctaAboutBtn {
  margin: 0 auto;
}

.ctaAboutBtn {
  display: none;
}

// MUI grid xs to small breakpoint
@media (min-width: 583px) {
  .ctaContentContainer {
    align-items: center;
    padding: 0;
    top: 0;
  }

  .ctaUpgradeBtn {
    width: 100%;
  }

  .ctaContent {
    align-items: flex-start;
    min-height: auto;
    text-align: unset;
  }

  .ctaImageWrapper {
    justify-content: flex-end;
  }

  .ctaHeading {
    text-align: left;
  }

  .ctaSubheading {
    text-align: left;
    align-items: flex-start;
    font-size: 16px;
  }

  .ctaAboutBtn {
    display: flex;
    width: 100%;
  }
}

/* hide host ad on smaller viewports
to match the actual cam player */
@media (max-width: $tabletLg) {
  .ctaHostAd {
    display: none;
  }
}

@media (min-width: $desktopLg) {
  .ctaHeading {
    font-size: 24px;
  }
}
