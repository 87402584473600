@import '../../styles/variables';


.conditionsBlock {
  width: 6px;
  height: 45px;
  border-radius: 99px;
  margin: auto 10px auto 0;

  @media (min-width: $tabletLg) {
    height: 50px;
  }
}

.conditionsBlockSmall {
  height: 42px;
  margin: 0 10px 0 0;

  @media (min-width: $tabletLg) {
    height: 45px;
  }
}

.isTooltip {
  height: auto;
}

.waveHeight {
  margin: 0;
}


.errorBox {
  background-color: rgba(#f5424b, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.errorBoxText {
  color: $error;
}
