@import '../../styles/variables';
@import '../../styles/mixins';

.ctaWrap {
  // display and width defined in component
  background: $paywall-background;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 #0000000f;
  flex-flow: column;
  height: calc(100% - 60px);
  margin: 0 0 30px 10px;
  padding: 50px 30px;
  position: absolute;
  text-align: center;
  top: 50px;
  z-index: 6;
  right: -20px;

  @media (min-width: $large-mobile) {
    right: -30px;
  }

  @media (min-width: $tablet) {
    right: -40px;
  }

  @media (min-width: $tabletLg) {
    top: 60px;
    right: 0;
  }
}

.typographyOverrides {
  font-size: 20px;
  margin-bottom: 20px;
}

.ctaTitle {
  margin: 20px 0;
  color: $product-secondary;
}

.ctaBulletPoints {
  display: flex;
  position: relative;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $desktopLg) {
    width: 350px;
    margin: 0 auto;
  }
}

.bulletPointWrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px;
  text-align: center;
  justify-content: center;
}

.bulletPointAvatar {
  margin-left: 20px;
}

.bulletPointText {
  flex: 1;
  max-width: 240px;
  margin: auto;
  color: $product-secondary;
}

.ctaButton {
  margin: 40px auto;
}

.ctaSkeletonImage {
  flex: 1;
  height: 50px;
  margin: auto 0;
  text-align: right;
  width: 50px;
}

.ctaTitleSkeleton {
  width: 100%;
  min-height: 108px;
  margin-bottom: 120px;
  height: 100%;
  flex: 1;
}
