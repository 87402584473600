@import '../../styles/variables';

$cta-image-blur: -110px 0px 60px 0px $paywall-background;

.ctaWrap {
  border-radius: 0;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: $tabletLg) {
    border-radius: 16px;
    justify-content: right;
    padding-right: 0px;
  }
}

.ctaContent {
  margin: 36px auto auto auto;
  justify-content: flex-start;
  background-color: $paywall-background;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  text-align: center;
  height: unset;
  box-shadow: none;
  border-radius: 0;

  @media (min-width: $mobile-width) {
    margin: 100px auto auto auto;
  }

  @media (min-width: $tabletLg) {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: $cta-image-blur;
    margin: 0;
    padding: 100px 60px 0 60px;
    max-width: 580px;
    min-width: 580px;
    text-align: left;
  }
}

.ctaTitle {
  margin: 0 auto 20px auto;
  max-width: 580px;

  @media (min-width: $tabletLg) {
    margin: 0 0 20px 0;
  }
}

.ctaBody {
  margin: 0 auto 18px auto;
  max-width: 580px;

  @media (min-width: $tabletLg) {
    margin: 0 0 18px 0;
  }
}

.ctaButton {
  margin: 0 auto;
  max-width: 155px;

  @media (min-width: $tabletLg) {
    margin: 0;
  }
}

.ctaCheckmark {
  margin-right: 8px;
  min-width: 32px;
  max-width: 32px;
}

.ctaBullets {
  margin: 0;
  padding: 0;
}

.ctaBulletPoint {
  display: flex;
  margin-bottom: 12px;
  text-align: left;

  p {
    line-height: 32px;
  }
}
