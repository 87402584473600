@import '../../styles/variables';

.ctaTitle,
.ctaBody,
.ctaButton {
  max-width: 640px;
}

.ctaButton {
  margin-top: 0;
}
