@import '../../styles/variables';
@import '../../styles/mixins';

.ctaWrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 88px;
  padding: 0 20px;
  background-color: $product-base;
  cursor: pointer;
  z-index: 4;
  transition: background-color 200ms linear;
  @media (min-width: $tablet) {
    height: 80px;
  }
}

.ctaWrap-scrolled {
  background-color: $brandSecondary;
}

.ctaInner {
  width: 100%;
  height: 100%;
}

.ctaContent {
  margin: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  @media (min-width: $tablet) {
    width: auto;
  }
}

.ctaText {
  padding: 0 30px 0 0;
  justify-content: center;
  text-decoration: none;
  @media (min-width: $tablet) {
    padding: 0 40px 0 0;
  }
}

.ctaButton {
  min-width: auto;
  white-space: nowrap;
}

.chevron {
  display: block;
  position: relative;
  cursor: pointer;
  svg {
    stroke: $product-secondary;
    transition: background-color 200ms linear;
  }
  @media (min-width: $tablet) {
    position: absolute;
    top: 32.5px;
    right: 40px;
    display: block;
  }
}

.chevron-scrolled {
  svg {
    stroke: $product-base;
    transition: background-color 200ms linear;
  }
}

.drawer {
  :global {
    .MuiDrawer-paper {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  @media (min-width: $tablet) {
    :global {
      .MuiDrawer-paper {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}

.drawerContainer {
  padding: 20px 20px 40px;
  @media (min-width: $tablet) {
    padding: 0;
  }
}

.drawerContentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.drawerContent {
  width: 100%;
  a {
    text-decoration: none;
  }
  @media (min-width: $tablet) {
    width: auto;
    max-width: 560px;
    padding: 80px 40px;
  }
}

.drawerTitle {
  margin: 20px 0;
  color: $product-secondary;
  @media (min-width: $tablet) {
    font-size: 48px;
    line-height: 56px;
  }
}

.drawerList {
  padding: 0 0 20px;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    padding: 10px;
    p {
      color: $product-body;
      padding-left: 20px;
      font-size: 14px;
      font-weight: 600;
      @media (min-width: $tablet) {
        font-size: 16px;
      }
    }
  }
}

.drawerButton {
  width: 100%;
}

.camMediaContainer {
  display: none;
  @media (min-width: $tablet) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 40px 0 0;
  }
}

.camMedia {
  @include aspect-ratio(16, 9);
  display: block;
  width: 100%;
  max-width: 740px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $grayLight;
  border-radius: 16px;
}

.spotDetails {
  position: absolute;
  bottom: 30px;
  left: 20px;
  h2,
  h3 {
    color: $product-base;
  }
}

.forecastMediaContainer {
  display: none;
  @media (min-width: $tablet) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 80px 0 40px;
  }
}

.forecastMedia {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/forecast-cta.png');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
}
