@import '../../styles/variables';
@import '../../styles/mixins';

.ctaWrapper {
  background-color: $paywall-background;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  // 75px (width of single day) * 5 days + 20px padding
  left: 395px;
  height: 100%;
  position: absolute;
  padding: 8px 8px 12px 20px;
  top: 0;
  width: 775px;
  justify-content: center;
  z-index: 4;
  @media (min-width: $tabletLg) {
    position: relative;
    left: 0px;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1440px) {
    position: unset;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 27px;
    padding-bottom: 27px;
    padding-left: unset;
    padding-right: unset;
  }
}

.ctaWrapperTenDay {
  background-color: $paywall-background;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
  // 75px (width of single day) * 10 days + 20px padding
  left: 770px;
  position: absolute;
  padding: 8px 8px 12px 20px;
  top: 0;
  justify-content: center;
  width: 380px;
  z-index: 4;

  @media (min-width: $tabletLg) {
    position: relative;
    left: 0px;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1440px) {
    position: unset;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 27px;
    padding-bottom: 27px;
    padding-left: unset;
    padding-right: unset;
  }
}

.ctaTitle {
  font-size: 14px;
  line-height: 18px;

  @media (min-width: 1440px) {
    font-size: unset;
    line-height: 24px;
  }
}

.ctaBody {
  font-size: 12px;
  line-height: 18px;
  @media (min-width: 1440px) {
    font-size: unset;
    line-height: 24px;
  }
}

.ctaButton {
  margin-top: 10px;
  width: 125px;

  @media (min-width: $tabletLg) {
    width: 135px;
  }

  @media (min-width: 1440px) {
    justify-content: center;
    margin-top: 0px;
    width: unset;
  }
}
