
@import '../../styles/variables';

.ctaWrap {
  border-radius: 16px;
  left: 0;
  margin: auto;
  max-height: 300px;
  max-width: 600px;
  min-width: 0px;
  padding: 30px 30px 40px 30px;
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;
  z-index: 12;

  @media (min-width: $large-mobile) {
    padding: 30px;
  }
}
.ctaContent {
  position: relative;
}

.ctaBody {
  margin-bottom: 0;
  max-width: 600px;
}

.ctaTitle {
  margin-bottom: 10px;
  max-width: 530px;
}

.ctaButton {
  margin-top: 35px;
  padding: 0 28px;

  @media (min-width: $large-mobile) {
    margin-top: 25px;
    margin-bottom: 5px;
  }
}
