@import '../../styles/variables';

.ctaImageWrapper {
  bottom: 0;
  display: none;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;

  @media (min-width: $tabletLg) {
    display: flex;
  }
}

.graphSwellSpectraCTAImage {
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto 0;
  background-image: url('../../../public/images/paywalls/graph-swell-spectra-cta-image.png');
  height: 443px;
  width: 960px;
}
