@import '../../styles/variables';
@import '../../styles/mixins';

.ctaWrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 74px;
  background-color: white;
  padding: 0 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 4;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 4px 4px 0 rgb(0 0 0 / 14%),
    0 1px 8px 2px rgb(0 0 0 / 12%);
}

.ctaInner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
}

.ctaContent {
  position: relative;
  display: flex;
  max-width: 400px;
  margin: auto;
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(236, 236, 236, 36.2%) 35%, rgba(255, 255, 255, 0%) 100%);
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: $product-light;
  }
}

.ctaText {
  justify-content: center;
  margin: auto 0;
  padding: 0 20px;
  text-decoration: none;
}

.ctaButton {
  white-space: nowrap;
}
