@import '../../styles/variables';

.ctaWrap {
  @media (max-width: $tabletLg) {
    width: 90vw;
    position: sticky;
    left: 20px;
  }
}
.ctaContent {
  position: unset;
}
