.premiumCamLabel {
  flex: 1 1 auto;
  margin: 4px 2px 0 0;
  display: flex;
  justify-content: right;
}

.premiumCamLabelInner {
  color: white;
  width: 51px;
  background: rgba(23, 23, 23, 0.4);
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  padding: 4px 2px;
  display: flex;
  justify-content: center;

  &:hover {
    color: white;
  }
}
