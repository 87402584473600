@import '../../styles/variables';

.chip {
  margin: 20px 0 0;
  padding: 2px 5px;
  background-color: $product-secondary;
  border-radius: 8px;
  h6 {
    color: $marineLayer;
    font-size: 15px;
  }
  svg {
    path {
      fill: $marineLayer;
    }
  }
  :global {
    .MuiChip-avatarSmall {
      height: 16px;
    }
  }
}
