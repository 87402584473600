@import '../../styles/variables';

.ctaImageWrapper {
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 215px;

  @media (min-width: $x-large-mobile) {
    top: 185px;
  }
}

.customForecastTooltipCTAImage {
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto 0;
  background-image: url('../../../public/images/paywalls/custom-forecast-tooltip-cta-image.png');
  height: 304px;
  width: 315px;

  @media (min-width: $x-large-mobile) {
    width: 448px;
    height: 433px;
  }

  @media (min-width: $tablet) {
    width: 544px;
    height: 525px;
  }

  @media (min-width: $desktopSm) {
    width: 640px;
    height: 618px;
  }
}
