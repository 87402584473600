.camMidrollUpsell {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 4;

  @media (max-width: 600px) {
    img {
      display: none;
    }
  }
}
